
export default {
  data: () => ({
    loading: false,
  }),

  methods: {
    start() {
      this.loading = true
    },
    finish() {
      this.loading = false
    },
  },
}
