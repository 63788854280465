
/**
 * @vue-prop {String} color - color of label
 * @vue-prop {Number} value - number
 * @vue-prop {Boolean} checked - checked status
 * @vue-prop {String} label - checkbox label
 * @vue-computed {Boolean} model - checkbox value
 */
export default {
  name: 'CheckboxComponent',
  model: {
    prop: 'checked',
    event: 'change',
  },
  props: {
    color: {
      type: String,
      default: '#445471',
    },
    value: {
      type: [Number, String],
      default: '',
    },
    checked: {
      type: [Boolean, Array],
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    price: {
      type: String,
      default: '',
    },
    /* Позиция маркера справа */
    isRight: {
      type: Boolean,
      default: false,
    },
    /* Будет ли добавлена картинка */
    withIcon: {
      type: String,
      default: '',
    },
  },
  computed: {
    model: {
      get() {
        return this.checked
      },
      set(val) {
        this.$emit('change', val)
      },
    },
  },
}
