
/**
 * @vue-prop {Number} time - seconds count for disable button after func executing
 * @vue-prop {Function} func - function for executing after button click
 */
export default {
  name: 'ButtonTimeoutComponent',
  props: {
    time: {
      type: Number,
      required: true,
    },
    func: {
      type: Function,
      required: true,
    },
    disabled: {
      type: Boolean,
    },
  },
  data: () => ({
    isCounting: false,
    btnTime: '',
    btnTxt: 'Отправить',
    timer: '',
  }),
  destroyed() {
    clearInterval(this.timer)
  },
  methods: {
    async send() {
      const funcSuccess = await this.func()

      if (funcSuccess) {
        this.btnTime = this.time
        this.isCounting = true

        this.timer = setInterval(() => {
          this.btnTime -= 1

          if (this.btnTime === 0) {
            this.isCounting = false
            this.btnTxt = 'Отправить еще раз'
            clearInterval(this.timer)
          }
        }, 1000)
      }
    },
  },
}
