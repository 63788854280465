
/**
 * @vue-prop {String} type - app style link type ['default','nav','location,'list',external', internal', 'tel','mail', 'user', 'whatsup', 'search', 'tel-big']
 * @vue-prop {String} linkType - app link type ['Product', 'Category']
 * @vue-prop {String} href - link url
 * @vue-prop {String} tag - dom element type
 * @vue-computed {String} link - transformed link depends of link type
 * @vue-computed {String} computedClass - styled class for current link
 */
export default {
  name: 'LinkComponent',
  props: {
    type: {
      type: String,
      default: '',
    },
    linkType: {
      type: String,
      default: '',
    },
    target: {
      type: String,
      default: '',
    },
    href: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    percent: {
      type: String,
      default: '',
    },
    parent: {
      type: Number,
      default: null,
    },
    tag: {
      type: String,
      default: 'a',
    },
    activeClass: {
      type: String,
      default: 'router-link-active',
    },
  },
  computed: {
    link() {
      switch (this.type) {
        case '':
          return '/'
        case 'tel':
          return `tel:${this.href}`
        case 'tel-big':
          return `tel:${this.href}`
        case 'mail':
          return `mailto:${this.href}`
        case 'nav':
          return `/${this.href}`
        case 'url':
          return this.href
        case 'external':
          return this.href
        case 'whatsup':
          return this.href
        case 'whatsup-icon':
          return this.href
        case 'search':
          return this.href
        case 'default':
          return this.href
        default:
          return `${this.href[0] === '/' ? this.href.slice(1, this.href.length) : `/${this.href}`}`
      }
    },
    appendLink() {
      return this.linkType === 'product'
    },
    // eslint-disable-next-line vue/return-in-computed-property
    computedClass() {
      switch (this.type) {
        case '':
          return ''
        case 'nav':
          return `link-nav`
        case 'tel':
          return `link-telephone`
        case 'tel-big':
          return `link-telephone-big`
        case 'location':
          return `cities`
        case 'mail':
          return `link-mail`
        case 'internal':
          return `link-internal`
        case 'external':
          return `link-external`
        case 'list':
          return `link-list`
        case 'whatsup':
          return `link-whatsup`
        case 'user':
          return `link-user`
        case 'btn':
          return `btn link-btn`
        case 'default':
          return `link-default`
      }
    },
  },
}
